<template>
  <div class="flex flex-col min-h-screen relative">
    <!-- This layout is for questionnaires and steppers. The main difference is the removal of the footer
    and no transitions on <slot />, allowing the stepper to progress smoothly. Also removal of the chat & back to top button -->
    <AlertBanner class="print:hidden" />

    <AlertContainer class="print:hidden" />

    <!-- Only show match alerts on mobile devices -->
    <!-- <mq-layout :mq="['xs', 'sm']">
      <AlertContainer class="print:hidden" />
    </mq-layout> -->

    <AppHeader class="print:hidden" />

    <slot class="flex-grow"></slot>
  </div>
</template>

<script setup lang="ts">
import { useSiteConfigStore } from '~/stores/siteConfig'

const siteConfigStore = useSiteConfigStore()

await siteConfigStore.fetch()
</script>
